@import '~antd/dist/antd.css';
@import './aligns';
@import './colors';
@import './utils';
@import './components/index.scss';
@import './pages/index.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";

body {
    padding: 0;
    margin: 0;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
        -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

.none {
    &__margin {
        margin: 0 !important;

        &--top { margin-top: 0 !important; }
        &--bottom { margin-bottom: 0 !important; }
        &--left { margin-left: 0 !important; }
        &--right { margin-right: 0 !important; }

        &--horizontal { margin-left: 0 !important; margin-right: 0 !important; }
        &--vertical { margin-top: 0 !important; margin-bottom: 0 !important; }

        &--all { margin: 0 !important; }

        &--small { margin: 8px !important; }
        &--medium { margin: 16px !important; }
        &--large { margin: 24px !important; }
    }

    &__padding {
        padding: 0 !important;

        &--top { padding-top: 0 !important; }
        &--bottom { padding-bottom: 0 !important; }
        &--left { padding-left: 0 !important; }
        &--right { padding-right: 0 !important; }

        &--horizontal { padding-left: 0 !important; padding-right: 0 !important; }
        &--vertical { padding-top: 0 !important; padding-bottom: 0 !important; }

        &--all { padding: 0 !important; }

        &--small { padding: 8px !important; }
        &--medium { padding: 16px !important; }
        &--large { padding: 24px !important; }
    }

    &__border { border: none !important; }
}

.full {
    &__height { height: 100%; }
    &__width { width: 100%; }
    &__size { width: 100%; height: 100%; }
}

.responsive {
    display: block;
    @include breakpoint(md) { display: none; }
}

.layout {
    background: linear-gradient(148.93deg, $black -40.22%, #0F1423 61.54%);
    margin: 0;
    padding: 86px 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-bottom: 0 !important;
}

.layout-dashboard {
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    background: linear-gradient(97deg, #000000 -65.43%, #0F1423 45.08%);
}
