.ant-tabs {

    &-tab-btn {
        font-size: 16px;
        letter-spacing: 0.28px;
        color: $white;
        font-weight: 500;
    }

    &-tab {

        &-btn:active {
            color: $primary;
        }

        &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: $primary;
            }
        }
    }

    &-top > .ant-tabs-nav::before {
        border-color: $neutralPrimaryAlt;
    }

    &-ink-bar {
        background: $primary;
    }
}
