
.ant-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 200px;

    &.ant-upload-select {
        display: block;
    }

    &-items {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        span {
            display: flex;
            text-align: center;
        }
    }
}

.upload-image {
    width: 100%;
    height: 100%;
    min-height: 200px;
    border: dashed 1px $primary;
    background-color: $lighterAlt;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    span {
        height: 100%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.image-crop {
    padding: 20px;
    max-height: 100vh;
    max-width: 90vh;
    width: auto !important;
    @include breakpoint(md) { width: 800px !important; }

    &__body {
        position: relative;
        margin-top: 12px;
        padding: 0;
        padding-top: 8px;
        backdrop-filter: blur(2px);
        background: hsla(0,0%,100%,.2);
        border-radius: 4px;
        z-index: 2;

        &--buttons {
            width: 100%;
            margin-top: 24px;
        }
    }

    &__save {
        background-color: $original !important;
        border-color: $original !important;
        color: $white !important;
    }
}


.instructions {
    background-color: $lighterAlt;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 4px 8px;
    border: solid 1.2px $tertiary;

    &-icon {
        color: $original;
        vertical-align: middle;
        margin-right: 8px;
    }
    span { color: $original }
}

.cropper {
    position: relative;
    height: calc(50vh - 200px);;
    width: 100%;
}

.aspects {
    display: flex;
    justify-content: center;

    &__4-3 {
        height: 20px;
        width: 30px;
    }
    
    &__3-2 {
        height: 18px;
        width: 30px;
    }
    
    &__1-1 {
        height: 25px;
        width: 25px;
    }

    &__2-3 {
        height: 30px;
        width: 18px;
    }

    &__3-4 {
        height: 30px;
        width: 20px;
    }

    &__ratio {
        padding: 4px;
        margin: 0 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &:hover{
            background-color: rgba(0, 0, 0, .1);
        }

        &--box {
            border: 1px solid rgba(0, 0, 0, 0.5);
            margin: auto 0;
        }

        &--name {
            text-decoration: underline;
            color: #777;
            font-size: 14px;
            letter-spacing: 1.5px;
            margin-top: 5px;
        }
    }
}