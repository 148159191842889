.steper {
    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 48px;

        h2 { color: $primary !important; }

        &--info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        
        &--help {
            color: $white;
            font-size: 20px;
            font-weight: 400;
            display: flex;
            letter-spacing: 0.25px;
            text-align: center;
        }

        &--avatar {
            margin: 0 auto;
            display: inline-block;
            svg {
                font-size: 100px;
                object-fit: contain;
                color: $primary;
            }
        }

        &--back {
            color: $primary;
            text-align: center;
            display: block;
            cursor: pointer;

            svg {
                vertical-align: middle;
                margin: 0 8px 4px 0;
            }
        }

    }
}

.icon__close {
    position: absolute;
    top: -64px;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
}