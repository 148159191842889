.footer {
    background-color: rgba(19, 38, 71, 0.3);
    padding: 64px 24px;
    width: 100%;

    @include breakpoint(md) { padding: 64px 24px; }
    @include breakpoint(lg) { padding: 64px 32px; }
    @include breakpoint(xl) { padding: 64px 64px; }
    @include breakpoint(xxl) { padding: 64px 128px; }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 48px;
        
        @include breakpoint(md) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
        }

        &--left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 24px;

            &-media {
                display: flex;
                justify-content: center;
                align-items: flex-start;

                &-icon {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    margin: 0 8px;
    
                    &:last-child { margin-right: 0; }
                    &:first-child { margin-left: 0; }
    
                    svg {
                        fill: $white;
                        height: 32px;
                        width: 32px;
                    }
                }
            }
        }

    }

    &__line {
        width: 100%;
        height: 0.08px;
        background-color: $quinary;
        border-radius: 8px;
        display: block;
        margin: 32px 0;
    }
}