.editor {
    width: 100%;
    overflow-y: scroll;
    border-radius: 4px;
    color: $white !important;

    &__public {
        padding: 8px 12px;
        border-radius: 8px;
        border: solid 1.4px $primary;

        font-size: 16px;
        font-weight: 400;

        &-title {
            color: $primary !important;
            font-size: 18px !important;
            margin-bottom: 0 !important;
        }

        &-text {
            color: $primary;
        }
    }

    &__quill {
        height: 100%;
    }

    &__button {
        span {
            color: $neutralDark !important;
        }
    }
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    height: 600px;
    padding-bottom: 8px;
}

.ql-snow .ql-picker-options {
    background: rgba(218, 218, 218, 0.08) !important;
}