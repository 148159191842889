.community {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    span { color: $white; }

    &__inner {
        width: 100%;
        background: rgba(218, 218, 218, 0.08);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 auto;
        position: relative;
        padding: 24px;
        min-height: 100vh;

        @include breakpoint(lg) {
            width: 1024px;
            max-width: 1024px;
        }
    }

    &__content {
        margin-top: 24px;
        width: 100%;
        gap: 32px;
        display: flex;
        flex-direction: column;
    }

    &__text {
        &-header {
            color: $primary !important;
        }

        &-description {
            font-size: 17px;
            font-weight: 400;
            text-align: justify;
            display: block;
        }

        &-total {
            margin-top: 12px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            font-size: 16px;
            font-weight: 500;
            color: $primary !important;
        }
    }

    &__chapter {
        background-color: $tertiary;
        border-radius: 8px;
        padding: 24px;
    }
}