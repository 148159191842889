.scrollable {
    &__container {
        padding: 24px;
        padding-left: 0;
        border-radius: 12px;
        width: 100%;
        overflow: auto;
        height: 30vh;
    }

    &__list {
        &--item {
            padding: 20px 12px;
            border-bottom: .2px solid transparent !important;
            margin-bottom: 8px;
            transition: all .1s ease-in-out;
            border-radius: 4px;

            &:hover {
                cursor: pointer;
                background-color: #292e3a;
                transition: all .1s ease-in-out;
            }

            &.active {
                background-color: #292e3a;
                border-bottom: .2px solid #292e3a !important;
                transition: all .1s ease-in-out;
                pointer-events: none;
            }

            &-title {
                color: $primary;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.28px;
                text-transform: capitalize !important;
            }

            &-description {
                color: $neutralTertiary;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.28px;
                text-transform: lowercase !important;
            }
        }
    }
}

.ant-empty-description {
    display: none;
}