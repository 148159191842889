.navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 0;
    position: relative;

    @include breakpoint(md) { padding: 0 24px; }
    @include breakpoint(lg) { padding: 0 32px; }
    @include breakpoint(xl) { padding: 0 64px; justify-content: space-between; }
    @include breakpoint(xxl) { padding: 0 128px; }

    &__logo {
        cursor: pointer;
        display: none;
        img {
            object-fit: contain;
        }

        @include breakpoint(xl) {
            display: block;
        }
    }


    &__burger {
        display: block;
        @include breakpoint(xl) { display: none; }
        padding: 0 24px;

        svg {
            font-size: 32px;
            color: $white;
            cursor: pointer;

            &:hover {
                transform: scale(0.96);
            }
        }
    }

    &__mobile {
        background: linear-gradient(148.93deg, $black -40.22%, #0F1423 61.54%);
        position: absolute;
        width: 100%;
        height: 120vh;
        top: -24px;
        left: 0;
        right: 0;

        gap: 48px;

        color: $white;
        padding: 8px 24px;
        z-index: 99;

        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-direction: column;

        &--logo {
            img {
                object-fit: contain;
            }
        }

        &-active { display: flex; }

        @include breakpoint(xl) {
            display: none;
            &-active { display: flex; }
        }

        &--close {
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            &:hover {
                transform: rotate(180deg);
                transition: all 0.3s ease-in-out;
            }
        }

        &--menu {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 32px;
            padding-top: 48px;

            a {
                color: $white;
            }

            &-item {
                font-size: 32px;
                font-weight: 600;
                cursor: pointer;

                &:hover {
                    color: $primary;
                    transition: all 0.3s ease-in-out;

                    .navbar__mobile--menu-item-line {
                        width: 100% !important;
                        background-color: $primary !important;
                        transition: all 0.3s ease-in-out;
                    }
                }

                &.active {
                    color: $primary;
                }

                &-line {
                    height: 4px;
                    border-radius: 8px;
                    width: 0;
                    display: block;
                    background-color: $white;
                }
            }
        }
    }

    &__items {
        display: none;
        align-items: center;
        justify-content: flex-end;

        @include breakpoint(xl) { display: flex; }

        a:last-child {
            background-color: $secondary;
            span {
                color: $primary;
            }
        }

        &--item {
            margin: 0 32px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.04px;
            border-radius: 4px;
            padding: 8px 12px;

            &-text { color: $white; }

            &.active {
                span { color: $primary; }
            }

            &-line {
                height: 4px;
                border-radius: 8px;
                width: 100%;
                display: block;
                background-color: $primary;
            }
        }
    }

}