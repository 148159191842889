$primary: #86C3FF !default;
$original: #1b4484 !default;
$secondary: #1E2736 !default;
$tertiary: #2A303E !default;
$quaternary: #1D2433 !default;
$quinary: #0D1527 !default;
$senary: #7AA7D3 !default;
$neutral: #132647 !default;
$neutralDark: #201f1e !default;
$black: #000000 !default;
$white: #ffffff !default;

$primaryAlt: #7abafb !default;

$light: #c7e0f4 !default;
$lighter: #deecf9 !default;
$lighterAlt: #eff6fc !default;

$neutralPrimary: #323130 !default;
$neutralPrimaryAlt: #3b3a39 !default;
$neutralSecondary: #605e5c !default;
$neutralSecondaryAlt: #8a8886 !default;
$neutralTertiary: #a19f9d !default;
$neutralTertiaryAlt: #c8c6c4 !default;
$neutralQuaternary: #d0d0d0 !default;
$neutralQuaternaryAlt: #dadada !default;
$neutralLight: #eaeaea !default;
$neutralLighter: #f4f4f4 !default;
$neutralLighterAlt: #f8f8f8 !default;

$blue: #1976d2 !default;
$yellow: #ffb900 !default;
$orange: #d83b01 !default;
$orangeLight: #ea4300 !default;
$orangeLighter: #ff8c00 !default;
$red: #d13438 !default;
$redLight: #fec1c3 !default;
$redLighter: #f9d7d7 !default;
$redDark: #a4262c !default;
$green: #00875A !default;
$greenLight: #05c988 !default;
$teal: #008272 !default;
$purple: #5c2d91 !default;
$magenta: #b4009e !default;

$gold: #AD9434 !default;
$platinum: #3E3E3E !default;
$diamond: #4F88EC !default;

$legacy: #0854A2 !default;
$thinkTank: #F29022 !default;
$brands: #A8A8A8 !default;
$roadMap: #EEBE33 !default;

$breakpoints: (
  xs:  380px,
  sm:  576px,
  md:  768px,
  lg:  992px,
  xl:  1200px,
  xxl: 1600px,
) !default;