.otp {

    &__text {
        &-description {
            color: $white;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 1.25px;
        }
    
        &-help {
    
        }
    }

    &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 0;
    }

    &-input {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: none;
        margin: 0 4px;
        
        @include breakpoint(md) {
            margin: 0 8px;
            border-radius: 6px;
        }

        @include breakpoint(lg) {
            margin: 0 12px;
            border-radius: 8px;
        }

        &::placeholder {
            color: $neutralLight;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 1.25px;
        }

        input {
            width: 40px !important;
            height: 40px;
            font-size: 18px;
            font-weight: 600;
            color: $original;

            @include breakpoint(sm) {
                width: 48px !important;
                height: 48px;
                font-size: 24px;
                font-weight: 700;
            }

            @include breakpoint(md) {
                width: 52px !important;
                height: 52px;
                font-size: 24px;
                font-weight: 700;
            }

            @include breakpoint(lg) {
                width: 64px !important;
                height: 64px;
                font-size: 22px;
                font-weight: 700;
            }
        }
        span {
            display: none;
        }
    }
    &-focus {
        border-color: $original;
    }
    &-disabled {

    }
    &-error {

    }
}