.ant-btn {
    align-items: center;
    background-color: $primary;
    border-color: $primary;
    border-radius: 4px;
    color: $neutralDark;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    min-height: 32px;
    height: fit-content;
    
    &:hover, &:focus {
        background-color: $primaryAlt;
        border-color: $primary;
        color: $neutralDark;
    }
}

.alternal-link {
    align-items: center;
    background-color: $primary;
    border-color: $primary;
    border-radius: 8px;
    color: $neutralDark;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    justify-content: center;
    min-height: 41px;
    height: fit-content;
    padding: 8px 12px;
    cursor: pointer;
    gap: 8px;
}