.card {
    &__event {
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
        background-color: $white;
        color: $original;
        padding: 16px 24px;
        border-radius: 8px;
        max-width: 100%;
        min-width: 100%;
        position: relative;

        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        align-items: center;

        cursor: pointer;
        gap: 12px;

        @include breakpoint(lg) {
            max-width: 100%;
            min-width: 50%;
        }

        &--content {
            &-avatar {
                background-color: $neutralLighter;
                display: flex;
                justify-content: center;
                align-items: center;

                svg { fill: $original; }
            }

            &-title {
                color: $original !important;
                font-weight: 700 !important;
                margin-bottom: 0;
            }

            &-time {
                font-weight: 500;
                font-size: 16px;
                color: $original;
                gap: 8px;

                span {
                    text-transform: capitalize;
                }
            }

            &-region {
                font-weight: 500;
                font-size: 16px;
                color: $original;
                gap: 8px;
            }

            &-button {
                background-color: $original !important;
                border-color: $original !important;
                border-radius: 4px;
                color: $white !important;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                padding: 2px 16px !important;

                &:hover {
                    background-color: $original !important;
                    color: $white;
                    opacity: 0.8;
                }
            }
        }
    }
}