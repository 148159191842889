.home {
    position: relative;

    &__section {
        padding: 64px 0;
        padding-top: 120px;

        &-avatar {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

        }

        &--more {
            color: $primary;
            font-size: 20px;
            font-weight: 600;
            cursor: pointer;
            margin-bottom: 16px !important;
            display: flex;
        }

        &--portrait {
            padding: 64px 24px;
            padding-top: 120px !important;
            padding-bottom: 120px !important;

            @include breakpoint(md) { padding: 64px 24px; }
            @include breakpoint(lg) { padding: 64px 32px; }
            @include breakpoint(xl) { padding: 64px 64px; }
            @include breakpoint(xxl) { padding: 64px 128px; }

            &-image {
                margin: 0 auto;
                width: 100%;

                @include breakpoint(md) {
                    width: 400px;
                }
            }
        }

        &--author {
            &-title {
                margin-bottom: 0 !important;
            }

            &-name {
                color: $primary;
                font-size: 20px;
                font-weight: 400;
            }

            &-description {
                font-size: 18px;
                height: 200px;
                overflow: hidden;
                margin-bottom: 0 !important;
                transition: all 0.5s ease-in-out;

                &.active {
                    height: fit-content;
                    transition: all 0.5s ease-in-out;
                }
            }

            &-button {
                border-radius: 8px;
                font-size: 24px;
                height: fit-content;
                padding: 4px 24px;
                width: 100%;
                font-weight: 500;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                @include breakpoint(md) {
                    width: fit-content;
                }
            }
        }

        &--review {
            &-card {
                border: solid 1px #132647;
                padding: 24px;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 24px;

                &-description {
                    font-size: 16px;
                    color: $white !important;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: block;
                    max-height: 150px;
                }
            }

            &-content {
                max-height: 300px;
                overflow-y: scroll;
                margin-top: 12px;
                margin-bottom: 12px;
                padding-right: 16px;
            }

            &-flag {
                border-radius: 4px;
                width: 48px;
                height: 28px;
            }
        }

        &--summary {
            padding: 64px 24px;
            padding-top: 280px !important;

            @include breakpoint(md) { padding: 64px 24px; }
            @include breakpoint(lg) { padding: 64px 32px; }
            @include breakpoint(xl) { padding: 64px 64px; }
            @include breakpoint(xxl) { padding: 64px 128px; }

            &-chapters {
                width: 100%;
                min-height: 300px;
                max-height: 700px;
                overflow-y: scroll;

                @include breakpoint(md) {
                    min-height: 600px;
                    max-height: 700px;
                }
                

                &-chapter {
                    background: rgba(27, 68, 132, 0.3);
                    border-radius: 8px;
                    padding: 24px 32px;
                    color: $white;
                    min-height: 300px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &-label {
                        color: $white;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    &-thoughts {
                        border: solid 1.4px $primary;
                        border-radius: 4px;
                        padding: 2px 8px;
                        margin: 0;
                        width: fit-content;
                        color: $primary !important;
                        font-weight: 500;
                        font-size: 16px;
                        cursor: pointer;

                        &:active {
                            transform: scale(0.96);
                        }
                    }

                    &-link {
                        gap: 8px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        width: 100%;
                        color: $primary;
                        font-size: 16px;
                        cursor: pointer;

                        svg {
                            vertical-align: middle;
                            color: $primary;
                        }
                    }
                }
            }
        }

        &--experiences {
            padding: 64px 24px;
            padding-top: 280px !important;

            @include breakpoint(md) { padding: 64px 24px; }
            @include breakpoint(lg) { padding: 64px 32px; }
            @include breakpoint(xl) { padding: 64px 64px; }
            @include breakpoint(xxl) { padding: 64px 128px; }

            &-members {
                width: 100%;
                min-height: fit-content;
                height: auto;
                max-height: 400px;
                overflow-y: scroll;
            }

            &-links {
                width: 100%;
                min-height: fit-content;
                height: auto;
                max-height: 250px;
                overflow-y: scroll;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                gap: 12px;
            }

            &-card {
                background-color: $quaternary;
                color: $white;
                padding: 12px 20px;
                padding-right: 48px;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 100%;
                min-width: 100%;
                position: relative;
            }

            &-description {
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 0 !important;
            }

            &-empty {
                width: 100%;
                height: 30vh;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-loading {
                width: 100%;
                height: 30vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            &-content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 32px;
            }

            &-footer {
                background-color: rgba(218, 218, 218, 0.08);
                padding: 24px;
                border-radius: 8px;
                margin-top: 16px;
            }

            &-pagination {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                width: 100%;

                @include breakpoint(md) {
                    justify-content: flex-end;
                }
            }

            &-avatar {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }            
        }

        &--author-data {
            padding: 64px 24px;
            padding-top: 180px !important;
            padding-bottom: 180px !important;

            @include breakpoint(md) { padding: 64px 24px; }
            @include breakpoint(lg) { padding: 64px 32px; }
            @include breakpoint(xl) { padding: 64px 64px; }
            @include breakpoint(xxl) { padding: 64px 128px; }

            &--brands {
                background-color: $quaternary;
                border-radius: 8px;
                padding: 12px 18px;
                gap: 24px;
                width: fit-content;

                display: flex;
                justify-content: center;
                align-items: center;

                display: none;

                @include breakpoint(md) {
                    display: flex;
                }

                &-avatar {
                    width: 180px;
                    height: fit-content;

                    &-qr {
                        width: 120px !important;
                        height: 120px !important;
                        object-fit: cover;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }

        &--available {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgba(19, 38, 71, 0.3);
            padding-bottom: 120px !important;

            &-logos {
                padding: 64px 24px;
                margin-top: 24px;
                width: 100%;
                cursor: pointer;

                @include breakpoint(md) { padding: 64px 24px; }
                @include breakpoint(lg) { padding: 64px 32px; }
                @include breakpoint(xl) { padding: 64px 64px; }
                @include breakpoint(xxl) { padding: 64px 128px; }
                
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 120px;

                h2 {
                    color: $primary !important;
                }
            }
        }

        &--feedback {
            padding: 64px 24px;
            padding-top: 120px !important;
            padding-bottom: 180px !important;

            @include breakpoint(md) { padding: 64px 24px; }
            @include breakpoint(lg) { padding: 64px 32px; }
            @include breakpoint(xl) { padding: 64px 64px; }
            @include breakpoint(xxl) { padding: 64px 128px; }

            &-cards {
                display: flex;
                flex-direction: column;
                gap: 24px;
                padding: 0 24px;
                padding-left: 0;
                max-height: 400px;
                overflow-y: scroll;

                @include breakpoint(lg) {
                    max-height: 600px;
                }

            }

            &-card {
                background-color: $quaternary;
                color: $white;
                padding: 18px 24px;
                padding-right: 48px;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 100%;
                min-width: 100%;
                position: relative;

                @include breakpoint(lg) {
                    max-width: 80%;
                    min-width: 50%;
                }

                @include breakpoint(xl) {
                    max-width: 75%;
                    min-width: 50%;
                }
                
                &-flag {
                    position: absolute;
                    right: -24px;
                    border-radius: 4px;
                    z-index: 2;
                    width: 48px;
                    height: 28px;
                }
                
                &-fixed-flag {
                    width: 48px;
                    height: 28px;
                    border-radius: 4px;
                }

                &-avatar {
                    background-color: $primary;
                    color: $secondary;
                    border: solid 1.4px $secondary;
                    font-weight: 600;
                }

                &-title {
                    color: $primary !important;
                    font-weight: 600;
                    font-size: 22px;
                }

                &-description {
                    font-size: 16px;
                    color: $white !important;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: block;
                }

                &-date {
                    color: $neutralSecondaryAlt;
                }
            }

            &-modal {
                &-title {
                    color: $original !important;
                    margin-bottom: 0 !important;
                }
                &-description {
                    font-size: 16px;
                    color: $white !important;
                }
            }

            &-select {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 24px;
                padding: 12px 24px;
                border-radius: 8px;
                width: 100%;
                min-height: 400px;
                margin: 0 auto;
                position: relative;
                overflow: hidden;

                span {
                    color: $white;
                    font-size: 18px;
                    font-weight: 400;
                }

                h4 { margin-bottom: 0; }

                @include breakpoint(md) { width: 600px; }

                &-avatar {
                    margin: 0 auto;
                    display: block;
                    align-items: center;
                }

                &-flag {
                    bottom: -24px;
                    right: -24px;
                    position: absolute;
                    opacity: .08;
                    z-index: 2;
                    filter: grayscale(50%);
                }
            }
        }

        &--divider {
            width: 16%;
            height: 1px;
            background-color: $white;
            margin: 84px 0 64px 0;
        }

        &--count {
            &-header {
                text-align: center;
                font-weight: 500 !important;
                margin-bottom: 8px !important;
            }

            &-subheader {
                margin-top: 0 !important;
                font-weight: 400 !important;
            }

            &-number {
                text-align: center;
                color: $primary !important;
                font-size: 56px !important;
                margin-bottom: 0 !important;
            }

            &-label {
                color: $white;
                font-size: 18px;
                font-weight: 400;
                text-align: center;
                margin-top: 0 !important;
            }

            &-link {
                color: $primary !important;
                font-weight: 400 !important;
                margin-top: 32px;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &--groups {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 84px;
            padding: 160px 24px;
            background-color: rgba(19, 38, 71, 0.3);
            position: relative;

            &-line {
                width: 100%;
                height: 0.1px;
                background-color: $neutral;
                border-radius: 8px;
                display: block;
                position: absolute;
                
                &.top {
                    top: -24px;
                }
        
                &.bottom {
                    bottom: -24px;
        
                }
            }

            &-header {
                text-align: center;
                font-size: 48px !important;
            }

            &-step {
                border-radius: 8px;
                background-color: rgba(218, 218, 218, 0.08);
                padding: 24px;

                &-title {
                    color: $primary !important;
                }

                &-number {
                    color: $primary;
                    font-size: 20px;
                    font-weight: 600;
                }

                &-description {
                    font-size: 16px !important;
                    
                    @include breakpoint(md) {
                        font-size: 18px !important;
                    }
                }
            }

            &-button {
                border-radius: 8px;
                font-size: 20px;
                padding: 8px 32px;
                height: fit-content;
                margin: 0 auto;
            }
        }

        &--schedule {
            &-calendar {
                background-color: rgba(19, 38, 71, 0.3);
                padding: 16px;
                border-radius: 12px;
                
                display: flex;
                justify-content: center;
                align-items: center;
                
                @include breakpoint(md) {
                    padding: 40px;
                }
                
                @include breakpoint(xl) {
                    padding: 24px;
                }
                
                &-header {
                    background-color: rgba(19, 38, 71, 0.3);
                    padding: 16px;
                    padding-left: 0;
                    border-radius: 8px;
                    width: fit-content;
                    color: $primary !important;
                }

                &-description {
                    color: $white;
                    font-size: 22px;
                    font-weight: 300;
                }

                &--events {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    padding: 16px;
                    padding-left: 0;
                    margin-top: 16px;
                    width: 100%;
                    min-height: 100px;
                    height: auto;
                    max-height: 400px;
                    overflow-y: scroll;

                    @include breakpoint(md) {
                        width: 750px;
                    }
                }

                &-frame {
                    width: 100%;
                    height: 450px;

                    @include breakpoint(md) {
                        width: 750px;
                        height: 500px;
                    }

                    @include breakpoint(xl) {
                        width: 800px;
                        height: 450px;
                    }

                    @include breakpoint(xl) {
                        width: 1100px;
                        height: 700px;
                    }
                }
            }
        }
    }
}
