.ant-steps {
    &-item {
        &-active {
            svg {
                color: $primary;
            }
        }

        &-icon {
            > .ant-steps-icon {
                font-size: 32px !important;
                color: $neutralPrimaryAlt;
            }
        }

    }
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: $primary;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: $primary;
    height: 1.4px;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: $neutralPrimaryAlt;
    height: 1.4px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: $primary;
    height: 1.4px;
}