.floating {
    align-items: center;
    bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    position: fixed;
    right: 16px;
    z-index: 999;

    @include breakpoint('md') {
        bottom: 64px;
        right: 40px;
    }

    &__button {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
    }
}