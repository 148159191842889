.ant-modal {
    &-content {
        background-color: $quaternary;
        border-radius: 8px;
        color: $white;
    }
}

.modal {
    &__wrapper {
        &--body {
            position: relative;
            margin-top: 20px;
            padding: 0;
            backdrop-filter: blur(2px);
        }
    }

    &__summary {
        &--preview {
            span {
                color: $white !important;
            }
            &-title {
                color: $white !important;
            }
            &-description {
                margin: 16px 0;
                font-size: 16px;
            }
        }
    } 
}