.screen {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    position: relative;
    align-items: center;
    background: rgba(218, 218, 218, 0.08);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 24px auto;
    padding: 32px 24px;
    width: 90%;
    
    @include breakpoint(md) {
        margin: 0 auto;
        padding: 32px 20px;
        width: 80%;
    }

    @include breakpoint(xl) {
        padding: 32px 24px;
        width: 75%;
    }

    &__inner {
        align-items: flex-start;
        display: flex;
        justify-content: center;
        max-height: 600px;
        min-height: 100%;
        overflow-y: scroll;
        width: 100%;

        @include breakpoint(md) {
            min-height: 100%;
            max-height: 700px;
        }

        @include breakpoint(xl) {
            min-height: 100%;
            max-height: 800px;
        }

        &--data {
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 24px;

            @include breakpoint(md) {
                width: 80%;
            }
        }

        &--icon {
            &-main {
                margin: 0 auto;
                display:inline-block;
            }
        }

        &--text {
            &-help {
                color: $white !important;
                font-size: 24px;
                text-align: center;
            }
        }
    }

}

.dashboard {
    min-height: 100vh;
    overflow-y: scroll;
    position: relative;
    padding: 24px;
    span {
        color: $white;
    }
}

.questions {
    padding: 32px 24px;
    padding-left: 0;

    > h3 {
        color: $primary !important; 
    }

    &__item {
        width: 100%;
        &-section {
            color: $primary !important;
            font-size: 18px;
            font-weight: 600;
        }
    }

    &__list {
        width: 100%;
        padding: 24px;
        padding-left: 0;
        gap: 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 20vh;
        overflow-y: scroll;
        max-height: 60vh;
        border-radius: 12px;
    }

    &__answers {
        &-empty {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        &-container {
            height: 32vh;
            overflow-y: scroll;
            padding: 16px 0;
        }

        &-text {
            font-size: 15px;
            font-weight: 500;
        }

        &-title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 8px;
        }
    }
}

.question {
    width: 100%;
    padding: 12px 24px;
    padding-left: 0;
    margin-bottom: 8px;
    margin-top: 8px;
    border-radius: 8px;

    &.active {
        background: rgba(218, 218, 218, 0.08);
        padding-left: 24px;
    }

    &__number {
        font-weight: 600;
        font-size: 19px;
    }

    &__text {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.25px;

        svg {
            vertical-align: middle;
            margin-left: 12px;
        }
    }

    &__update {
        display: block;
        font-size: 14px;
        color: $neutralSecondary !important;
    }

    &__actions {
        margin-top: 4px;
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;

        &-btn {
            background-color: $secondary !important; 
            border-color: $secondary !important;
            border-radius: 4px;
            letter-spacing: 0.25px;
            padding: 4px 16px !important;
            height: 35px !important;
            font-size: 14px;

            span {
                color: $primary;
            }
        }
    }
}

.invite {
    &__member {
        color: $white !important;
        font-weight: 600;
        font-size: 16px;
        width: 100%;
        margin-top: 12px;
        cursor: pointer;
        padding: 8px 12px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(218, 218, 218, 0.08);
    }
}

.rotate {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}